<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" label-width="77px" class="ele-form-search d-flexspabet"
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
              <div>
                <el-button @click="showEdit=true" class="ele-btn-icon addbtn " size="small">添加权限</el-button>
              </div>
            <div class="d-flex">
              <el-form-item label="权限名称:">
                <el-input v-model="table.where.name" placeholder="请输入权限名称" clearable/>
              </el-form-item>
               <div style="margin-left:10px">
                <el-button type="primary" @click="$refs.table.reload()"  class="ele-btn-icon">搜索</el-button>
              </div>
            </div>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 260px)" highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="权限ID" width="150" align="center" fixed="left" show-overflow-tooltip/>
          
          <el-table-column prop="name" label="权限名称" min-width="300"  show-overflow-tooltip  align="center"/>
          <el-table-column label="操作" min-width="300" align="center" :resizable="false"  fixed="right">
            <template slot-scope="{row}">
              <el-link slot="reference" icon="el-icon-suitcase-1" type="danger"
									:underline="false" v-if="permission.includes('sys:role:update') && row.id !== 1" @click="edit(row)" >编辑名称</el-link>
<!--              <el-link v-if="row.id!=1&&permission.includes('sys:role:edit')" @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false">修改</el-link>-->
              <el-link v-if="row.id!=1&&permission.includes('sys:role:permission')" @click="auth(row)" icon="el-icon-suitcase-1" type="primary" :underline="false">分配权限</el-link>
              <el-popconfirm title="确定要删除此菜单吗？" @confirm="remove(row)" class="ele-action">
              <el-link slot="reference" icon="el-icon-delete" type="danger"
									:underline="false" v-if="permission.includes('sys:role:delete') && row.id !== 1">删除</el-link>
              </el-popconfirm>
            
              
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'编辑权限':'添加权限'" width="400px" :visible.sync="showEdit"
               @closed="editForm={}" :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">
        <el-form-item label="权限名称:" prop="name">
          <el-input v-model="editForm.name" placeholder="请输入权限名称" clearable/>
        </el-form-item>
<!--        <el-form-item label="管理员权限:" prop="type_name">-->
<!--           <el-select v-model="editForm.type_name"  class="ele-block" clearable>-->
<!--&lt;!&ndash;                <el-option label="总管理" :value="1"/>&ndash;&gt;-->
<!--                <el-option label="省代理" :value="2"/>-->
<!--                <el-option label="市代理" :value="3"/>-->
<!--                <el-option label="广告商" :value="5"/>-->
<!--                <el-option label="县运营" :value="4"/>-->
<!--              </el-select>-->
<!--        </el-form-item>-->
      </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </el-dialog>
    <!-- 权限分配弹窗 -->
    <el-dialog v-dialogDrag title="分配权限" :visible.sync="showAuth" width="400px" @closed="editForm={}" :destroy-on-close="true"
               :lock-scroll="false">
      <el-scrollbar style="height:50vh;" wrapStyle="overflow-x: hidden;">
        <el-tree ref="authTree" :data="authData" :props="{label:'title'}" node-key="id"
                 :default-expand-all="true" :default-checked-keys="authChecked" show-checkbox/>
      </el-scrollbar>
      <div slot="footer">
        <el-button @click="showAuth=false">取消</el-button>
        <el-button type="primary" @click="saveAuth">确认分配</el-button>
      </div>
    </el-dialog>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../../../components/footer"
export default {
  name: "SysRole",
  components:{Footer},
  data() {
    return {
      table: {url: '/role/index', where: {}},  // 表格配置
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {code:'请选择'},  // 表单数据
      editRules: {  // 表单验证规则
        name: [
          {required: true, message: '请输入权限名称', trigger: 'blur'}
        ],
        type_name: [
          {required: true, message: '请选择管理员权限', trigger: 'change'}
        ]
      },
      showAuth: false,  // 显示权限分配弹窗
      authData: []  // 权限分配数据
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    
    /* 权限树选中数据 */
    authChecked() {
      let checked = [];
      this.$util.eachTreeData(this.authData, d => {
        if (d.checked && (!d.children || !d.children.length)) checked.push(d.id);
      });
      return checked;
    }
  },

  methods: {
    /* 显示编辑 */
    edit(row) {
      
      this.editForm = Object.assign({}, row);
      this.showEdit = true;
      //console.log(this.editForm)
    },
    /* 保存编辑 */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          this.$http.post('/role/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /* 删除 */
    remove(row) {
      if (!row) {  // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的角色吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/role/delete', {id: ids}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else {  // 单个删除
        const loading = this.$loading({lock: true});
        this.$http.post('/role/delete', {id:row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      }
    },
    /* 显示分配权限弹窗 */
    auth(row) {
      this.editForm = Object.assign({}, row);
      this.authData = [];
      const loading = this.$loading({background: 'transparent'});
      this.$http.get('/role/getPermissionList?role_id=' + row.id).then(res => {
        loading.close();
        this.showAuth = true;
        if (res.data.code === 0) {
          this.authData = this.$util.toTreeData(res.data.data, 'id', 'pid');
          //console.log(this.authData)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        loading.close();
        this.$message.error(e.message);
      });
    },
    /* 保存权限分配 */
    saveAuth() {
      const loading = this.$loading({lock: true});
      let ids = this.$refs.authTree.getCheckedKeys().concat(this.$refs.authTree.getHalfCheckedKeys());
      this.$http.post('/role/savePermission?role_id=' + this.editForm.id, ids).then(res => {
        loading.close();
        if (res.data.code === 0) {
          this.showAuth = false;
          this.$message({type: 'success', message: res.data.msg});
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        loading.close();
        this.$message.error(e.message);
      });
    }
  }
}
</script>

<style scoped>

</style>