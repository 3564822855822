

<!-- tinymce富文本编辑器组件 -->
<template>
 <div style="padding:10px;text-align:center;position:fixed;bottom:0;left:0;right:0;background:#fff;margin-left:55px;margin-top:100px" class="footer">
        <div>Copyright © 2020 livingtrip.uyl.cn. All Rights Reserved. 依蓝云（北京）科技有限公司 版权所有 ISP证：鲁B1-20190022</div>
        <div>系统版本：v3.1.11</div>
      </div>
</template>

<script>


export default {
    name: "SysUser",
    data() {
      return {}
    },
    
    created(){
      var footer_bottom=document.getElementsByTagName('body').offsetTop
      // //console.log(footer_bottom)
    //   var footer_bottom=$("body").contents().find(".footer").offset().top;
    //   //console.log(footer_bottom)
    //   var footer_height=$("#iframe1").contents().find(".footer").height();
    //   ////console.log(w_height+','+footer_bottom+','+footer_height+','+(w_height-footer_bottom-footer_height));
    //   if(w_height-footer_bottom-footer_height>0){
    //     $("#iframe1").contents().find(".footer").css('position','fixed');
    //   }else{
    //     $("#iframe1").contents().find(".footer").css('position','static');
    //   }
    }

 
}
</script>

<style>


</style>